@import "./colors.scss";

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

button {
  cursor: pointer;
  display: inline-block;
  border-radius: 0;
  width: auto;
  text-decoration: none;
  padding: 4px 1rem;
  border: 2px solid #3b3c3d;
  font-family: Soho Gothic W01 Bold, a-otf-ud-shin-go-pr6n, Open Sans Bold,
    Arial, sans-serif;
  color: #3b3c3d !important;
  line-height: 1;
  background-color: transparent;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /*
  &:hover,
  &:focus {
    color: #fff !important;
    background-color: #3b3c3d;
    text-shadow: none;
  }
  */
}

button a {
  color: white;
  text-decoration: none;
}

a,
a button,
a span {
  text-decoration: none;
}

button {
  .fab {
    margin-right: 10px;
  }

  &.small {
    padding: 2px;
    width: 30px;
    min-width: auto;
    border-radius: 100%;
    border-color: #fff;
    outline: none;
    border: 1px solid;

    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.buttons-area {
  padding: 20px 0;

  button {
    margin-right: 3rem;
    margin-bottom: 10px;
  }
}

.table-buttons {
  button {
    background-color: transparent;
    border: none;
    color: #a4b6b7;
  }
}

.fake-anchor {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
  text-align: left;

  color: blue;

  &:hover,
  &:focus {
    color: blue;
    text-decoration: underline;
  }
}

.MuiButtonBase-root.collapse {
  padding: 0;
  margin-left: 5px;
}

.MuiButton-label .fas {
  margin: 0 5px;
}

span.copy-to-clipboard {
  bottom: 5px;

  .MuiButton-root {
    min-width: auto !important;
  }
}

button.copy-to-clipboard {
  margin-left: 4px;
  width: 26px;
  border-radius: 10px;
  padding: 4px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.MuiTooltip-tooltip {
  background: #ee7021 !important;
}

.Mui-disabled {
  opacity: 0.5;
}
