.login-area {
  background-color: #fff;
  margin: 0 auto;
  width: 720px;
  text-align: center;
  padding: 1rem 2rem 2rem 1rem;

  .login-button {
    width: 16rem;
  }

  button {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 0.8rem 2rem 1rem !important;
    font-size: 1rem;
  }
}
