@import "./colors.scss";

.collapsible-section {
  border-bottom: 1px dotted $orange1;
  padding-bottom: 1rem;

  &:not(:first-child) {
    padding-top: 0.5rem;
  }

  > .header-section {
    float: left;

    button {
      font-size: 1rem;

      i.fas {
        margin-right: 0px !important;
      }
    }
  }

  > .content-section {
    padding-top: 2px;
    margin-left: 42px;
  }

  h2.collapsible-title {
    margin-top: 5px;
    cursor: pointer;
  }

  &.collapsed {
    .collapsible-items {
      display: none;
    }
  }
}
