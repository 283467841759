.MuiDialogTitle-root {
  padding: 16px 22px !important;
}

.dialog {
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .preloader-mask:not(.overlay) .preloader-icon:before {
    left: 35%;
    top: 35%;
  }

  .MuiDialogContentText-root {
    white-space: break-spaces;
    word-break: break-word;
  }

  &.normal {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 30vw;
    }
  }

  &.medium {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 50vw;
    }
  }

  &.big {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 80vw;
    }
  }

  &.full {
    .MuiDialog-paperWidthSm {
      max-width: none;
      width: 100vw;
      height: 100vh;
    }
  }

  .table-wrapper {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 1.5rem;
  }

  dl {
    margin-top: 0;
  }
}

.dialog-single-button,
.dialog-buttons {
  margin: 0 15px 8px 15px;
}

.dialog-buttons,
.dialog .buttons-area {
  // space-between makes sure that Confirm and Cancel buttons
  // are as far from each other as possible.
  display: flex;

  justify-content: space-between !important;

  &.dialog-single-button {
    justify-content: flex-end !important;
  }
  align-items: flex-end;
  border-bottom: none !important;
  margin: 0 15px 8px 15px;
  padding: 0;

  button {
    margin-right: 0;
  }
}

.dialog .buttons-area {
  margin: 0;
}

.MuiDialogContent-root .buttons-area {
  border-bottom: 1px dotted #ddd;
}

.dialog {
  .MuiFormControl-root {
    width: 100%;
  }
}

.generic-dialog {
  &.warning {
    .MuiPaper-root {
      h2 {
        &:before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f071";
          color: #ce9938;
          margin-right: 10px;
        }
      }
    }
  }
}
