table {
  margin: 1rem 0;

  thead {
  }

  th,
  td {
    padding: 0.5rem 0.5rem 0.5rem 0;
  }
}

.table-region {
  table,
  .table-children {
    margin-bottom: 1rem;
  }
}

.dynamic-table-region {
  position: relative;
}

.table-pagination {
  margin-top: 1rem;
  margin-left: -13px;
  margin-bottom: 5px;

  .buttons button {
    margin: 0 0.5rem;
  }

  > div {
    justify-content: space-between;
    display: flex;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
  font-size: 1rem;
}

tr {
  border-bottom: 1px solid #ddd;
}

a,
tr,
th,
td {
  &::-moz-selection {
    color: inherit;
    background: transparent;
  }

  &::selection {
    color: inherit;
    background: yellow;
  }
}

tbody tr {
  &.selected {
    a,
    th,
    td {
      color: #580b0b;
    }
    background-color: #9fe4ea;

    a,
    th,
    td,
    span {
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }

  &.cut {
    opacity: 0.5;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  &:hover {
    background-color: #f7f7f7;

    &.selected {
      background-color: #9fe4ea;
    }
  }
}

.highlight-selected {
  tr {
    cursor: pointer;
    border-bottom: 1px solid #ddd;

    &.selected {
      background-color: gold;
    }
  }

  tbody tr {
    &:hover {
      &.selected {
        background-color: gold;
      }
    }
  }
}

th {
  text-align: left;

  &.icon {
    width: 42px;
  }
}

th,
td {
  padding: 5px 10px 5px 0;
  vertical-align: top !important;

  &:first-child {
    padding-left: 10px;
  }

  span {
    white-space: nowrap;
  }

  &.actions {
    text-align: right;
    white-space: nowrap;

    button {
      padding: 2px;
      width: 30px;
      min-width: auto;
      border-radius: 100%;
      border-color: #fff;
      outline: none;
      border: 1px solid transparent;
      background: #ececec;
      margin-left: 10px;
      font-size: 1rem;

      i.fas {
        margin: 5px;
      }

      &:hover,
      &:focus {
        border: 1px solid #eee;
      }
    }
  }

  &.status {
    text-align: left;
  }
}

.MuiTableCell-root {
  padding-left: 0 !important;
}

.no-results {
  margin-top: 2rem;
  display: block;
}
