@import "./colors.scss";

.multi-select-wrapper {
  display: flex;

  .MuiInput-underline {
    &:after,
    &:before {
      display: none;
      content: none;
    }
  }

  select {
    height: 100% !important;
    min-height: 20vh;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }

  > div {
    flex: 50%;
  }
}

.multi-select-summary {
  padding-left: 1rem;
}
