#search-area {
  color: #fff;
  background-color: #3b3c3d;
  text-align: center;
  padding: 1rem;
  position: relative;

  label {
    margin-bottom: 1rem;
  }

  .MuiInputBase-root.MuiInputBase-fullWidth {
    flex-direction: column;
  }

  input {
    background-color: #fff;
    border-radius: 2rem;
    margin: 1rem;
    padding: 1rem 2rem;
    max-width: 630px;
    font-size: 20px;
  }

  .preloader-mask.covering .preloader-icon:before {
    top: 34%;
    left: 48%;
  }
}

#results-area {
  width: 80%;
  margin: 2rem auto;
  color: #000;

  .sn-overview {
    h2 {
      margin-bottom: 0.1rem;
    }
  }
}

.no-results {
  text-align: center;

  h2 {
    font-size: 1.78rem;
  }
}
