h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Soho Gothic W01 Bold, a-otf-ud-shin-go-pr6n, Open Sans Bold,
    Arial, sans-serif;
}

p {
  font-size: 18px;
  line-height: 1.45;
}

.notes p,
.note {
  font-size: small;
  margin: 0.2rem 0;
  padding: 0;
}

.small-note {
  font-size: 12px;
  color: gray;
}

span[tabindex] {
  cursor: pointer;
}

label,
span {
  font-family: Soho Gothic W01 Regular, a-otf-ud-shin-go-pr6n, Open Sans, Arial,
    sans-serif;
}

*::selection {
  color: #fff;
  background-color: #c6168d;
}

label:after {
  content: ":";
  display: inline-block;
}
