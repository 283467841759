.serial-number-form {
  h3,
  p {
    margin: 0 0 4px 0;
  }

  .current-value-area,
  .edit-value-area {
    margin-bottom: 1rem;

    span[tabindex] {
      margin-top: 0.5rem;
      display: inline-block;
    }
  }

  .recover-area {
    padding: 2rem 1.5rem;

    strong {
      margin-left: 1rem;
    }
  }
}
