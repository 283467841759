@import "./login.scss";
@import "./batteries.scss";
@import "./search.scss";

.headline {
  text-transform: uppercase;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-title {
  background-color: #7e7e7e !important;
  padding: 0.5rem 0;

  .logos {
    margin: 0;
  }

  h1,
  h2 {
    color: #fff;
  }

  h2 {
    text-align: center;
    font-size: 3.4rem;
    font-weight: normal;
    margin-bottom: 1.2rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logos {
  text-align: center;
  align-items: center;

  li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2rem;
  }
}

.app-header {
  height: 66px;
  background-color: #fff;
  padding: 1.5rem 2rem;
  position: relative;

  .ug-menu {
    right: 20px;
    top: 92%;
    min-width: 250px;

    &:before {
      content: "";
      position: absolute;
      top: -13px;
      right: 1.375rem;
      width: 28px;
      height: 13px;
      background-repeat: no-repeat;
      background-image: url("./img/menu-triangle.png");
    }

    li {
      text-align: center;

      span {
        padding: 6px 0;
      }
    }
  }

  > div {
    float: right;
  }
}
