@import "./alerts.scss";
@import "./buttons.scss";
@import "./cards.scss";
@import "./dialogs.scss";
@import "./errors.scss";
@import "./links.scss";
@import "./loaders.scss";
@import "./search.scss";
@import "./menus.scss";
@import "./structure.scss";
@import "./tabs.scss";
@import "./tables.scss";
@import "./lists.scss";
@import "./text.scss";
@import "./forms.scss";
@import "./images.scss";
@import "./pagination.scss";
@import "./chips.scss";
@import "./selects.scss";
@import "./collapsible.scss";

body {
  font-family: sans-serif;
  overflow-y: scroll;
}
