a.selected {
  color: #3f51b5;

  svg {
    color: #3650e3;
  }
}

.drawer a {
  color: #000;
  text-decoration: none;
}

.details-link {
  font-size: 1.5rem;
}
